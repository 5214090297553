// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  humefanEditorBaseUrl : "https://editor.dev.humefan.humelab.com",
  humefanApiBaseUrl : "https://api.dev.humefan.humelab.com",
  humefanCdnBaseUrl: "https://media.dev.humefan.humecloud.com",
  apiBaseUrl: "https://api.dev.screenlab.humelab.com",
  carAdApiBaseUrl: "https://api.dev.automobile.humecloud.com",
  cmnAppApiBaseUrl: "https://api.dev.common.humelab.com",
  cmnEventApiBaseUrl: "https://api.dev.common.humecloud.com",
  cmnEmailApiBaseUrl: "https://api.dev.common.humelab.com",
  cmnUserApiBaseUrl: "https://api.dev.common.humelab.com",
  mediaApiBaseUrl: "https://api.dev.screenlab.humelab.com",
  deviceApiBaseUrl: "https://api.dev.screenlab.humelab.com",
  externalApiBaseUrl: "https://api.dev.screenlab.humelab.com",
  scenarioApiBaseUrl: "https://api.dev.screenlab.humelab.com",
  hheInstitutionApiBaseUrl: "https://api.dev.humehealth.humecloud.com",
  horApiBaseUrl: "https://api.dev.humeorder.humecloud.com",
  htiApiBaseUrl: "https://api.dev.humetips.humecloud.com",
  hhoApiBaseUrl: "https://api.dev.humehospitality.humecloud.com",
  htiAppBaseUrl: "https://app.dev.humetips.com",
  htvApiBaseUrl: "https://api.dev.htv.humecloud.com",
  hcoApiBaseUrl: "https://api.dev.hco.humecloud.com",
  reeAdApiBaseUrl: "https://api.dev.realestate.humecloud.com",
  scrMenuApiBaseUrl: "https://api.dev.screenlab.humelab.com",
  statsApiBaseUrl: "https://api.dev.sta.humecloud.com",
  keycloak: {
    url: "https://auth.dev.common.humelab.com/auth",
    realm: "scr",
    clientId: "scr-portal"
  },
  sentry: {
    dsn: "https://afaa28c4ba8e4778bdd8c08ccda3705f@o474392.ingest.sentry.io/6227907",
    tracePropagationTargets: ["localhost"],
    tracesSampleRate: 1.0
  },
  postermywall: {
    server: "https://api.postermywall.com",
    clientId: "A8sUXDHVkd62urBYfB6ECqEud2NRplxO",
    clientSecret: "oS0TFutiJLCBAiy2",
    tokenEndpoint: "/v1/oauth/token",
    authorizationEndpoint: "/v1/oauth/authorize",
    redirectUri: "https://manager.dev.humecloud.com/screenlab/templates/postermywall/auth"
  },
  azimut: {
    oauthConfig: {
      server: 'https://auth.cricket-pro.fr',
      clientId: 'aad0878f-79a5-4291-9020-691e0c849ee4',
      clientSecret: 'Cjx8Q~4pQBCS-QTdS4cLzAh-X7ZNvMfsxtQdsdr9',
      tokenEndpoint: '/auth.cricket-pro.fr/oauth2/v2.0/token?p=b2c_1a_signup_signin_cricket',
      authorizationEndpoint: '/auth.cricket-pro.fr/oauth2/v2.0/authorize?p=b2c_1a_signup_signin_cricket'
    },
    redirectUri: 'https://manager.dev.humecloud.com/screenlab/azimut/auth/redirect'
  },
  stripePublishableKey: "pk_test_51IRzHbAEsxXKA442VhGgrIK33paERsBBbaIphLPldpGKlT5uFj3DQuFjrg0AdGJoTborJ9WCt1Ga00y42N9UDU4K00Wz4oyv5Q",
  ftpPartnersUrl: "ftp.dev.partners.humecloud.com"
}
// environment.sentry.tracingOrigins.push(
//   environment.apiBaseUrl,
//   environment.humefanEditorBaseUrl,
//   environment.humefanApiBaseUrl,
//   environment.humefanCdnBaseUrl,
//   environment.cmnAppApiBaseUrl,
//   environment.cmnEventApiBaseUrl,
//   environment.cmnEmailApiBaseUrl,
//   environment.cmnUserApiBaseUrl,
//   environment.mediaApiBaseUrl,
//   environment.deviceApiBaseUrl,
//   environment.scenarioApiBaseUrl,
//   environment.hheInstitutionApiBaseUrl,
//   environment.reeAdApiBaseUrl,
//   environment.scrMenuApiBaseUrl,
//   environment.statsApiBaseUrl
// )

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
